import classNames from 'classnames'
import React from 'react'
import {usePagination} from 'react-instantsearch'

const Pagination = () => {
  const {pages, currentRefinement, nbPages, refine, createURL} = usePagination({
    padding: 3,
  })

  return (
    <div className="mt-10 flex w-full justify-center">
      <ul className="relative flex items-center">
        {currentRefinement > 0 ? (
          <li className="absolute -left-8 mr-3 rotate-180">
            <a
              href="#"
              className="flex items-center no-underline"
              onClick={() => refine(currentRefinement - 1)}
            >
              <div className="ml-3">
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8017 6.95381C14.1812 6.57432 14.1812 5.95804 13.8017 5.57855L8.9443 0.721138C8.56482 0.341652 7.94853 0.341652 7.56905 0.721138C7.18956 1.10062 7.18956 1.71691 7.56905 2.09639L10.7719 5.29621H1.45781C0.920459 5.29621 0.486328 5.73035 0.486328 6.2677C0.486328 6.80505 0.920459 7.23918 1.45781 7.23918H10.7689L7.57208 10.439C7.1926 10.8185 7.1926 11.4348 7.57208 11.8143C7.95157 12.1937 8.56785 12.1937 8.94734 11.8143L13.8048 6.95684L13.8017 6.95381Z"
                    fill="#005E75"
                  />
                </svg>
              </div>
            </a>
          </li>
        ) : null}
        {pages.map(page => {
          const linkClass = classNames({
            'no-underline': page !== currentRefinement,
            'underline  decoration-dats-s1 text-dats-s5 font-bold':
              page === currentRefinement,
          })
          return (
            <li className="mx-2 text-sm font-semibold" key={page}>
              <a
                href={createURL(page)}
                className={linkClass}
                onClick={event => {
                  event.preventDefault()
                  refine(page)
                }}
              >
                {page + 1}
              </a>
            </li>
          )
        })}
        {nbPages > 3 ? (
          <>
            <li className="mx-2 text-sm font-semibold">...</li>
            <li className="mx-2 text-sm font-semibold">{nbPages}</li>
          </>
        ) : null}
        {currentRefinement + 1 < nbPages ? (
          <li className="absolute -right-8 ml-3">
            <a
              href="#"
              className="flex items-center no-underline"
              onClick={() => refine(currentRefinement + 1)}
            >
              <div className="ml-3">
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8017 6.95381C14.1812 6.57432 14.1812 5.95804 13.8017 5.57855L8.9443 0.721138C8.56482 0.341652 7.94853 0.341652 7.56905 0.721138C7.18956 1.10062 7.18956 1.71691 7.56905 2.09639L10.7719 5.29621H1.45781C0.920459 5.29621 0.486328 5.73035 0.486328 6.2677C0.486328 6.80505 0.920459 7.23918 1.45781 7.23918H10.7689L7.57208 10.439C7.1926 10.8185 7.1926 11.4348 7.57208 11.8143C7.95157 12.1937 8.56785 12.1937 8.94734 11.8143L13.8048 6.95684L13.8017 6.95381Z"
                    fill="#005E75"
                  />
                </svg>
              </div>
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  )
}

export default Pagination
