import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import {FaqCtaStoryblok} from 'types/storyblok-types'
import linkTypeChecker from 'util/linkTypeChecker'

type faqCtaProps = {
  faqCta: FaqCtaStoryblok
}

const faqCta = ({faqCta}: faqCtaProps) => {
  return (
    <Link href={linkTypeChecker(faqCta.link)} className="block w-full " target={faqCta.link.target}>
      <button className="w-full">
        <div className="flex items-center justify-between rounded-dats bg-dats-s10 p-4">
          <div className="flex flex-row items-center">
            {faqCta.image ? (
              <div className="relative ml-4 mr-8 size-6">
                <Image
                  src={faqCta.image.filename}
                  alt={faqCta.image.alt ? faqCta.image.alt : ''}
                  fill
                />
              </div>
            ) : null}
            <div className="flex flex-col justify-between text-left text-sm text-dats-s5">
              <p className="font-rubik font-bold">{faqCta.title}</p>
              <p>{faqCta.text}</p>
            </div>
          </div>
          {faqCta.icon ? (
            <div className="relative ml-4 flex  size-6">
              <Image
                src={faqCta.icon.filename}
                alt={faqCta.icon.alt ? faqCta.icon.alt : ''}
                fill
              />
            </div>
          ) : null}
        </div>
      </button>
    </Link>
  )
}

export default faqCta
