import React, {useEffect, useState} from 'react'
import {
  AlgoliaNoResultsStoryblok,
  FaqCtaStoryblok,
  SearchInputValidationMessageStoryblok,
} from 'types/storyblok-types'
import Hit from './Hits'
import {CustomMenu} from './CustomMenu'
import {renderRichText} from '@storyblok/react'
import FaqCta from './FaqCta'
import Image from 'next/image'
import Pagination from './Pagination'
import {Hits, useInstantSearch} from 'react-instantsearch'
import {Analytics} from 'lib/analytics'
import _ from 'lodash'

export interface HeaderSearchBarProps {
  noResults: AlgoliaNoResultsStoryblok
  faqCta: FaqCtaStoryblok
  searchValidationMessage: SearchInputValidationMessageStoryblok
}

function HeaderSearchBar({
  noResults,
  faqCta,
  searchValidationMessage,
}: HeaderSearchBarProps) {
  const {results, indexUiState, status} = useInstantSearch()
  const [lastFacets, setLastFacets] = useState<string[]>([])
  const noResultsRichText = renderRichText(noResults.text, {})

  useEffect(() => {
    if (status !== 'idle') {
      return
    }

    if (results && results.query.length >= 3) {
      // Construct active filters list.
      const facetsData = results.hierarchicalFacets?.[0]?.data ?? []
      const facets = facetsData
        .filter(facet => {
          return facet.isRefined === true
        })
        .map(facet => {
          return {
            name: facet.name,
            category: 'tags',
          }
        })

      const facet_names = facets.map(facet => facet.name)
      const is_removal = lastFacets.length > facet_names.length

      Analytics.getInstance().sendSiteSearchEvent(
        results.query,
        results.nbHits,
        facets,
      )

      if (lastFacets.length > 0 || facets.length > 0) {
        const flatFacets = facets.map(facet => facet.name)
        if (_.difference(flatFacets, lastFacets).length === 0) {
          // No changes in facets, so nothing new to send.
          return
        }

        Analytics.getInstance().sendSiteSearchFilterChangeEvent(
          is_removal,
          results.query,
          results.nbHits,
          facets,
          facets,
        )
      }

      setLastFacets(facets.map(facet => facet.name))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  return (
    <div className="w-full pb-5">
      {results.nbHits > 0 ? (
        <>
          <div className="flex  w-full flex-col items-center justify-start  rounded-dats bg-white ">
            <div className="w-full">
              <CustomMenu />
              <Hits hitComponent={Hit} className="" />
            </div>
          </div>
          <div className={`${results.nbHits <= 3 ? 'lg:mt-32' : 'lg:mt-0'}`}>
            <FaqCta faqCta={faqCta} />
          </div>
        </>
      ) : null}
      {indexUiState.query && results.nbHits === 0 && status !== 'loading' ? (
        <div className="mt-28 text-center text-sm">
          <span className="mb-6 block font-rubik font-bold text-dats-s6">
            {noResults.title}
          </span>
          <span dangerouslySetInnerHTML={{__html: noResultsRichText}} />
        </div>
      ) : null}
      {!indexUiState.query ? (
        <div className="mt-20 flex w-full flex-col items-center">
          <Image
            src={searchValidationMessage.image.filename}
            alt={
              searchValidationMessage.image.alt
                ? searchValidationMessage.image.alt
                : ''
            }
            width={143}
            height={100}
          />
          <p className="mt-10 text-center">{searchValidationMessage.text}</p>
        </div>
      ) : null}
      {results.nbHits > 0 ? <Pagination /> : null}
    </div>
  )
}

export default HeaderSearchBar
