import classNames from 'classnames'
import {MenuItem} from 'instantsearch.js/es/connectors/menu/connectMenu'
import {useEffect} from 'react'
import {useMenu} from 'react-instantsearch'

export function CustomMenu() {
  const {items, refine} = useMenu({
    attribute: 'story.content.search_categories.content.title',
  })

  let orderedItems = items.sort((a, b) => {
    if (a.label === 'Offre') return -1
    if (b.label === 'Offre') return 1
    return a.label.localeCompare(b.label)
  })

  const itemClass = (item: MenuItem) =>
    classNames('text-sm mr-4 ', {
      'border-b-4 border-dats-s1 font-bold': item.isRefined,
      'font-semibold': !item.isRefined,
    })
  useEffect(() => {
    if (
      items.filter(menuItems => menuItems.isRefined === false).length ===
        items.length &&
      items.length > 0
    ) {
      refine(items[0].value)
    }
  }, [items, refine])

  return (
    <ul className="mt-3  flex overflow-x-auto text-dats-s3 scrollbar-none md:flex-wrap">
      {orderedItems.map(item => {
        return (
          <li
            key={item.label}
            onClick={() => refine(item.value)}
            className={itemClass(item)}
          >
            <button type="button">
              <span>{item.label}</span>
              <span className="ml-1">({item.count})</span>
            </button>
          </li>
        )
      })}
    </ul>
  )
}
