import {defineIndexByLang} from 'components/Layout/Header/AlgoliaSearch/AlgoliaContainer'
import {AlgoliaHitType} from 'components/Layout/Header/AlgoliaSearch/Hits'

export type algoliaUiState = {
  [indexId: string]: {
    configure?: {
      filters?:
        | string
        | readonly string[]
        | readonly (string | readonly string[])[]
        | undefined
    }
  }
}

export default function FixUrlForStoryFromAlgolia(
  hit: AlgoliaHitType['hit'],
  lang: string,
  algoliaUiState: algoliaUiState,
) {
  const index = defineIndexByLang(lang)
  const facetFiltersFromAlgoliaUiState =
    algoliaUiState[index].configure!.filters
  let tagFromFacetFilters = null
  if (typeof facetFiltersFromAlgoliaUiState === 'string') {
    tagFromFacetFilters = facetFiltersFromAlgoliaUiState
  }
  if (typeof facetFiltersFromAlgoliaUiState === 'object') {
    tagFromFacetFilters = (facetFiltersFromAlgoliaUiState as string[]).find(
      facet => facet.includes('story.tag_list'),
    )
  }
  const tag = tagFromFacetFilters?.split(':')[1]

  const slugFromAlgolia = hit.story.full_slug

  const tagMapping =
    tag === 'Individual'
      ? 'particulier'
      : lang === 'nl'
      ? 'professional'
      : 'professionnel'

  if (slugFromAlgolia.includes('common')) {
    const fixedSlug = slugFromAlgolia.replace('common', `${tagMapping}`)
    return fixedSlug
  }
  return hit.story.full_slug
}
